import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import img from "../images/header.jpg";
import TopNavigation from "../components/TopNavigation";

const BackgroundImage = styled.div`
  background-image: url(${img});
  background-size: cover;
  background-position: center;
`;

function HomeHeader() {
  return (
    <BackgroundImage className="bg-light p-5 mb-4 rounded-3 text-center text-light">
      <div className="container-fluid py-5">
        <h1 className="display-5 fw-bold">Oost & Ryk</h1>
        <p className="fs-4 font-italic">Design, solutions and advisory</p>
      </div>
    </BackgroundImage>
  );
}

function Home() {
  return (
    <>
      <TopNavigation />
      <HomeHeader />
      <Container>
        <p>Oost & Ryk helps businesses with their digital opportunities.</p>
        <Row>
          <Col>
            <h3>Header</h3>
            <p>Text</p>
          </Col>
          <Col>
            <h3>Header</h3>
            <p>Text</p>
          </Col>
          <Col>
            <h3>Header</h3>
            <p>Text</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
