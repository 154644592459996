import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";
import TopNavigation from "./components/TopNavigation";

function App() {
  return (
    <div className="App">
      <TopNavigation />
      <Container>
        <Outlet />
      </Container>
    </div>
  );
}

export default App;
