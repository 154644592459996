import React from "react";

function Jumbotron() {
  return (
    <div className="bg-light p-5 mb-4 rounded-3">
      <div className="container-fluid py-5">
        <h1 className="display-5 fw-bold">A big jumbotron!</h1>
        <p className="col-md-8 fs-4">
          Some catchy line for simple explaination.
        </p>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <Jumbotron />
      <h2>About Page</h2>
      <main>
        <p>This section contains information about...</p>
        <br />
      </main>
    </>
  );
}

export default About;
