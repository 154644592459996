import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <h2>404 - Page not found</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </>
  );
}

export default NotFound;
